import * as React from "react"
import { DefaultLayout } from "../layouts/DefaultLayout"
import { FullWidthComponent } from "../components/FullWidthComponent"
import ReactMarkDown from "react-markdown"
import { sizes } from "../layouts/sizes"
import { useTranslation } from "react-i18next"
import { localizedPage } from "../i18n/Localized"
import { graphql, useStaticQuery } from "gatsby"

const content = `
# Open source

Maphi is made possible by a number of open-source projects listed below. We are dedicated to returning value to the community by providing pull requests and open-sourcing many of our own components. Many can be seen on the [GitHub profile](https://github.com/TheLartians) of our lead developer. 

## Projects used by Maphi

Below is a list of potentially used open-source projects and their respective licence.

`

export default localizedPage(() => {
  const disclaimer = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "txt" }
          relativeDirectory: { eq: "acknowledgements" }
        }
      ) {
        nodes {
          name
          childPlainText {
            content
          }
        }
      }
    }
  `).allFile.nodes.map((n: any) => n.childPlainText.content) as string[]

  return (
    <DefaultLayout title={"Open Source"}>
      <FullWidthComponent centerStyle={{ margin: sizes.margins.normal }}>
        <ReactMarkDown source={content} />
        <code style={{ color: "black", whiteSpace: "pre-line" }}>
          {disclaimer.join("\n-----\n")}
        </code>
      </FullWidthComponent>
    </DefaultLayout>
  )
})
